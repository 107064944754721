<section class="container mx-auto py-4 px-4">
    <a class="inline-flex items-center" routerLink="..">
        <i class="pi pi-chevron-left mr-2"></i>
        {{ 'navigation.back' | translate }}
    </a>

    <h1 class="text-4xl text-center md:text-left self-center pt-6 pb-6 font-semibold break-words">
        {{ resolveTicketZone(selectedTicket) | translate }} {{ resolveTicketArea(selectedTicket) | translate }} {{ resolveDiscount(selectedTicket) |translate}}
    </h1>
    <h2 class="text-3xl p-pt-lg-6 pb-2 translate-desc font-bold"> {{ "shop.description" | translate}} <p class="underline">{{ "shop.descriptionPart2" | translate}}</p></h2>
    <div class="flex-container">
        <div *ngFor="let line of selectedTicket.transportLines; index as idx"
             style="margin-top: 20px; margin-bottom: 20px; width: 70px; height: 50px; background-color: {{ line.parameters.color }};"
             (click)="addSelectedLine(idx, line)"
             [class.active]="isActive === idx"
        >
            <p style="text-align: center; margin-top: 5px; font-size: 30px; font-weight: bold; color: white; pointer-events: none !important;">{{ line?.name.slice(0,2) }}</p>
        </div>
    </div>
    <div class="grid ticket-type-section-wrapper gap-5 grid-cols-1 md:grid-cols-2">
        <section>
            <!-- ------------------------------- ticket -------------------------------- -->
            <div class="ticket-wrapper flex justify-center md:justify-start">
                <div class="grid-container bus-ticket bg-cover" title="{{ resolveTicketZone(selectedTicket) }}">
                    <div class="icon"></div>
                    <div class="content">
                        <ng-container *ngIf="selectedTicket">
                            <div class="content-name ticket-font">{{ resolveTicketZone(selectedTicket) | translate  }}</div>
                            <div class="content-body">
                                <div class="content-item  discount">{{ resolveTicketArea(selectedTicket) | translate }}</div>
                                <div class="content-item ticket-font discount">{{ resolveDiscount(selectedTicket) |translate}}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="md:w-3/4">
                <h2 class="text-lg pt-2 pb-2">{{ 'ticket.price' | translate }}
                    : {{ selectedTicket?.priceWithTax/ 100 | currency: 'PLN' }}</h2>
                <h2 class="text-lg font-bold">{{ 'ticket.statisticDataDescription' | translate }}</h2>
<!--              nazwa nameEn jest brana z kolumny określającej nazwę linii do celów rozliczeniowyh  -->
                <h2 class="text-lg">{{ 'ticket.line' | translate }}
                    : {{ selectedLine?.nameEn }}</h2>
                <h2 class="text-lg">{{ 'ticket.route' | translate}}
                    : {{'ticket.from' | translate}}: {{ selectedLine?.parameters.from}} - {{ 'ticket.to' | translate}}: {{selectedLine?.parameters.to}}</h2>
                <h2 class="text-lg">{{ 'ticket.relation' | translate }}
                    : {{ selectedLine?.parameters.fullName }}</h2>
                <div [innerHtml]="selectedTicket?.metadata.detailedDescription" class="md:w-3/4"></div>
            </div>
        </section>

        <section class="pt-5 md:pt-0">
            <div class="grid gap-2">
                <div>
<!--                    <div [ngClass]="!userData ? 'text-gray-400' : ''" class="pb-1">{{ 'shop.selectCard' | translate }}-->
<!--                        *-->
<!--                    </div>-->
<!--                    <p-dropdown-->
<!--                        optionLabel="cardNumber"-->
<!--                        [(ngModel)]="selectedTickerCarrier"-->
<!--                        [options]="userData?.ticketCarriers"-->
<!--                        [placeholder]="'shop.selectCard' | translate"-->
<!--                        class="tickets-type-card-dropdown"-->
<!--                        [group]="true"-->
<!--                        [disabled]="!userData"-->
<!--                    >-->
<!--                        <ng-template let-group pTemplate="group">-->
<!--                            <div-->
<!--                                class="p-d-flex p-ai-center gap-2 px-4 py-2"-->
<!--                                *ngIf="!group.hidden"-->
<!--                                (click)="onNewCardSelect()"-->
<!--                            >-->
<!--                                <img src="/assets/icons/rr-plus-black.svg" class="icon-img w-6 h-6"/>-->
<!--                                <span>{{ group.label }}</span>-->
<!--                            </div>-->
<!--                        </ng-template>-->
<!--                    </p-dropdown>-->
                </div>
                <div
                    *ngIf="isSeasonTicket"
                    [formGroup]="monthlyTicketForm"

                >
                    <div
                        [class.text-gray-400]="!userData"
                        class="pb-1"
                    >{{ 'tickets.startStation' | translate }}*
                    </div>
                    <p-dropdown
                        [options]="stations$ | async"
                        optionLabel="name"
                        optionValue="lp"
                        formControlName="from"
                        [placeholder]="'calculator.choose' | translate"
                        [disabled]="!userData"
                        [filter]="true"
                    ></p-dropdown>
                    <div
                        *ngIf="
                            monthlyTicketForm.controls.from.invalid &&
                            (monthlyTicketForm.controls.from.dirty || monthlyTicketForm.controls.from.touched)
                           "
                        class="text-sm text-red-500"
                    >
                        <div *ngIf="monthlyTicketForm.controls.from.errors.stationsShouldBeDifferent">
                            {{ 'validation.stationsShouldBeDifferent' | translate }}
                        </div>
                        <div *ngIf="monthlyTicketForm.controls.from.errors.restrictedStations">
                            {{ 'validation.restrictedStations' | translate }}
                        </div>
                    </div>

                    <div [class.text-gray-400]="!userData"
                         class="pb-1">{{ 'tickets.endStation' | translate }}*
                    </div>
                    <p-dropdown
                        [options]="stations$ | async"
                        optionLabel="name"
                        optionValue="lp"
                        formControlName="to"
                        [placeholder]="'calculator.choose' | translate"
                        [disabled]="!userData"
                        [filter]="true"
                    ></p-dropdown>
                    <div
                        *ngIf="
                            monthlyTicketForm.controls.to.invalid &&
                            (monthlyTicketForm.controls.to.dirty || monthlyTicketForm.controls.to.touched)
                           "
                        class="text-sm text-red-500"
                    >
                        <div *ngIf="monthlyTicketForm.controls.to.errors.stationsShouldBeDifferent">
                            {{ 'validation.stationsShouldBeDifferent' | translate }}
                        </div>
                        <div *ngIf="monthlyTicketForm.controls.to.errors.restrictedStations">
                            {{ 'validation.restrictedStations' | translate }}
                        </div>
                    </div>
                </div>
                <div class="md:grid md:justify-between gap-3 md:grid-cols-1">
                    <div class="pb-1">
                        <div class="whitespace-nowrap pb-1" [ngClass]="!userData ? 'text-gray-400' : ''">
                            {{ 'shop.ticketValidForOneMonth' | translate }}
                        </div>
                        <div class="w-full">
                            <p-calendar
                                [(ngModel)]="activatedAt"
                                [disabled]="true"
                                showButtonBar="true"
                                todayButtonStyleClass="hidden"
                                dateFormat="MM yy"
                                styleClass="w-full"
                                [placeholder]="activatedAt"
                                readonlyInput="true"
                                view="month"
                            ></p-calendar>
                        </div>
                    </div>
<!--                    <div class="pb-2">-->
<!--                        <div class="pb-1"-->
<!--                             [ngClass]="!userData ? 'text-gray-400' : ''">{{ 'tickets.quantity' | translate }}*-->
<!--                        </div>-->
<!--                        <p-inputNumber-->
<!--                            [(ngModel)]="quantity"-->
<!--                            class="p-fluid"-->
<!--                            [disabled]="!userData || isSeasonTicket"-->
<!--                        ></p-inputNumber>-->
<!--                    </div>-->
                </div>
                <div class="text-2xl text-theme text-center pt-5 pb-2">
                    {{ 'shop.total' | translate }}
                    : {{ (selectedTicket?.priceWithTax * quantity) / 100 | currency: 'PLN' }}
                </div>
            </div>

            <div class="grid items-end mt-4">
                <button
                    pButton
                    type="button"
                    [label]="moveToCartButtonLabel | translate"
                    [disabled]="(userData && isSeasonTicket && !monthlyTicketForm.valid) || !selectedLine"
                    (click)="moveToCart('regular')"
                ></button>
            </div>


            <div *ngIf="!userData" class="grid items-end mt-4">
                <button
                    pButton
                    type="button"
                    [label]="moveToCartButtonLabelGuest | translate"
                    [disabled]="(isSeasonTicket && !monthlyTicketForm.valid) || !selectedLine"
                    (click)="moveToCart('guest')"
                ></button>
            </div>

            <div *ngIf="!userData" class="pt-2">
                {{ 'login.noAccount' | translate }}
                <span class="cursor-pointer text-active" [routerLink]="['/uzytkownik/rejestracja']">
                    {{ 'registration.register' | translate }}
                </span>
            </div>
        </section>
    </div>
</section>

<p-dialog
    class="grid"
    [header]="'tickets.newCard' | translate"
    [(visible)]="displayNewCardDialog"
    position="center"
    modal="true"
    [closable]="true"
    [draggable]="false"
    (onHide)="resetForm()"
    [resizable]="false"
>
    <form [formGroup]="ticketCardInfoFormGroup" class="customer-info-form">
        <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
            <label for="cardName">{{ 'profile.tickets.cardName' | translate }}: </label>
            <input
                class="flex md:w-3/5 w-full"
                type="text"
                formControlName="cardName"
                pInputText
                inputid="cardName"
                maxlength="200"
                [placeholder]="'profile.tickets.newCard' | translate"
            />
        </div>
        <div
            *ngIf="
                ticketCardInfoFormGroup.controls.cardName.invalid &&
                (ticketCardInfoFormGroup.controls.cardName.dirty || ticketCardInfoFormGroup.controls.cardName.touched)
            "
            class="text-sm text-red-500"
        >
            <div *ngIf="ticketCardInfoFormGroup.controls.cardName.errors.required">
                {{ 'validation.required' | translate }}
            </div>
        </div>
        <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
            <label for="cardNumber">{{ 'profile.tickets.cardNumber' | translate }}: </label>
            <p-inputMask
                #inputMaskCard
                class="flex md:w-3/5 w-full"
                type="text"
                formControlName="cardNumber"
                inputid="cardNumber"
                mask="9999 99** **** 9999"
                placeholder="9999 99** **** 9999"
            ></p-inputMask>
        </div>
        <div
            *ngIf="
                ticketCardInfoFormGroup.controls.cardNumber.invalid &&
                (ticketCardInfoFormGroup.controls.cardNumber.dirty ||
                    ticketCardInfoFormGroup.controls.cardNumber.touched)
            "
            class="text-sm text-red-500"
        >
            <div *ngIf="ticketCardInfoFormGroup.controls.cardNumber.errors.required">
                {{ 'validation.required' | translate }}
            </div>
        </div>
        <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
            <label for="cardExpiration">{{ 'profile.tickets.expirationDate' | translate }}: </label>
            <p-inputMask
                class="flex md:w-3/5 w-full"
                type="text"
                formControlName="cardExpiration"
                inputid="cardExpiration"
                mask="99/99"
                placeholder="01/01"
            ></p-inputMask>
        </div>
        <div
            *ngIf="
                ticketCardInfoFormGroup.controls.cardExpiration.invalid &&
                (ticketCardInfoFormGroup.controls.cardExpiration.dirty ||
                    ticketCardInfoFormGroup.controls.cardExpiration.touched)
            "
            class="text-sm text-red-500"
        >
            <div *ngIf="ticketCardInfoFormGroup.controls.cardExpiration.errors.required">
                {{ 'validation.required' | translate }}
            </div>
        </div>
        <div class="flex md:flex-row flex-col justify-start md:justify-between md:items-center pb-2">
            <label for="cardOwner">{{ 'profile.tickets.cardOwner' | translate }}: </label>
            <input
                class="flex md:w-3/5 w-full"
                type="text"
                formControlName="cardOwner"
                pInputText
                inputid="cardOwner"
                maxlength="200"
            />
        </div>
        <div
            *ngIf="
                ticketCardInfoFormGroup.controls.cardOwner.invalid &&
                (ticketCardInfoFormGroup.controls.cardOwner.dirty || ticketCardInfoFormGroup.controls.cardOwner.touched)
            "
            class="text-sm text-red-500"
        >
            <div *ngIf="ticketCardInfoFormGroup.controls.cardOwner.errors.required">
                {{ 'validation.required' | translate }}
            </div>
        </div>
        <div class="w-full flex items-center pt-7">
            <button
                class="md:w-96 w-full"
                pButton
                type="submit"
                (click)="createTicketCarrier()"
                [label]="'profile.tickets.approve' | translate"
                [disabled]="!ticketCardInfoFormGroup.valid"
            ></button>
        </div>
    </form>
</p-dialog>
