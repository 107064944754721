/* eslint-disable class-methods-use-this */
import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { environment } from '../../../../../../../environments/environment';
import {
    calculateTicketDiscountId, calculateTicketZoneAndArea,
    calculateViewTicketDiscount,
    calculateViewTicketDuration,
    calculateViewTicketZone
} from '../../../../../../shared/utils/resolve-ticket-data';
import * as AppStateActions from '../../../../../modules/state/app-state.actions';
import { AppState } from '../../../../../modules/state/app.state';
import {Ticket, TransportZone} from '../../../../../modules/state/interfaces';
import {map} from "rxjs/operators";
import {MessageService} from "primeng/api";

@Component({
    selector: `app-transport-authority-tickets`,
    templateUrl: `transport-authority-tickets.component.html`,
    styleUrls: [`./transport-authority-tickets.component.scss`],
})
export class TransportAuthorityTicketsComponent {
    @Select(AppState.selectedTransportAuthority)
    selectedTransportAuthority$!: Observable<any>;

    @ViewChild(`dv`, {
        static: true,
    })
    dv: any;
    showDiscounts: boolean = false;

    selectedTransportAuthority!: any;
    tickets: any[] = [];

    // types of tickets
    types: any[] = [];
    selectedType: string = ``;

    // type of discount
    discounts: any[] = [];
    selectedDiscount: string = ``;

    // areas of tickets
    areas: any[] = [];
    selectedArea: any= null;

    filteredByType: any[] = [];
    filteredByDiscount: any[] = [];
    filteredByArea: any[] = [];
    filteredTickets: any[] = [];

    constructor(private readonly store: Store, private readonly translateService: TranslateService, private readonly messageService: MessageService,
    ) {}

    ngOnInit() {
        this.store.dispatch(new AppStateActions.ShowHeaderDropdown(true));
        this.store.dispatch(new AppStateActions.HideCart(true));
        this.translateService.get('test').subscribe((transleted: string) => {
            this.types = environment.tags.map(({name, value}: any) => ({
                name: this.translateService.instant(name),
                value,
            }));
        })
        this.selectedTransportAuthority$.subscribe((selectedTransportAuthority) => {
            this.selectedTransportAuthority = selectedTransportAuthority;
            if (!this.selectedTransportAuthority) {
                return;
            }
            this.tickets = this.selectedTransportAuthority.tickets;
            this.discounts = this.selectedTransportAuthority.tags.filter((discount: any)=>{
                return  discount.tagGroupId === `e1631456-6f31-42b8-b7e8-03686b3bc208`
            }).map((discountObj: any)=>{
                return {name: this.translateService.instant(discountObj.name), id: discountObj.id};
            });

            const areas: any [] = [];
            this.selectedTransportAuthority.transportZones.forEach(
                (zone: any) => {
                    const name = zone.name;
                    zone.tags.forEach(
                        (tag: any) => {
                            areas.push({zone: this.translateService.instant(name), area: this.translateService.instant(tag.name)})
                        }
                    )
                }
            )
            this.areas = areas;

        });
        this.resetPredefinedFilters()
    }

    handleAreaClick(event: MouseEvent, callback: Function){
        if (this.selectedType === ``){
            event.stopPropagation();
            this.messageService.add({
                severity: `error`,
                summary: this.translateService.instant(`action.error`),
                detail: this.translateService.instant(`tickets.choseTypeOfTicket`)
            });
        } else {
            callback();
        }

    }
    resolveTicketArea(ticket: any) {
        let area = ``;
        if (ticket.tags && ticket.tags.length > 0 ) {
            ticket.tags.forEach(function (tag: any) {
                if (tag.tagGroupId === "5a3bc3cc-e6b2-4379-8074-208a00c7c8d4"){
                     area = tag.name;
                }
            })
        }
        return this.translateService.instant(area);
    }
    resolveZoneAndAra(ticket:any){
        return calculateTicketZoneAndArea(ticket);
    }
    resolveDiscount(ticket: any){
        return calculateViewTicketDiscount(ticket);
    }
    resolveTicketDiscountId(ticket: any){
        return calculateTicketDiscountId(ticket);
    }

    resolveTicketZone(ticket: any) {
        return calculateViewTicketZone(ticket);
    }

    resolveTicketDuration(ticket: any) {
        return calculateViewTicketDuration(ticket);
    }

    filterBy=()=> {
        this.filteredTickets = this.dv.value;
        if (this.selectedType == `normal` && this.selectedDiscount !== ``) {
            this.resetPredefinedFilters()
            this.selectedType = `normal`
        }
        if (this.selectedType.length !== 0) {
            this.filteredTickets.forEach((item: any) => {
                this.filterByTypes(item);
            });
            this.filteredTickets = this.filteredByType;
            this.filteredByType = [];
        }

        if (this.selectedDiscount !== null){
            this.filteredTickets.filter((ticket:Ticket)=>{
                this.filterByDiscount(ticket);
            })
            this.filteredTickets = this.filteredByDiscount
            this.filteredByDiscount = [];
        }

        if (this.selectedArea !== null ){
            this.filteredTickets.filter((ticket: Ticket) => {
                this.filterByArea(ticket);
            })
            this.filteredTickets = this.filteredByArea;
            this.filteredByArea = [];
        }
        this.dv.filteredValue = this.filteredTickets;
    }
    private filterByDiscount(ticket: Ticket){
        const selectedDiscount = this.selectedDiscount;
        const ticketDiscountId = this.resolveTicketDiscountId(ticket);
        if (selectedDiscount === ticketDiscountId){
            this.addElementToArrayIfNotAddedYet(this.filteredByDiscount, ticket);
        }

    }

    private filterByArea(ticket: Ticket) {
        const selectedArea = this.selectedArea;
        const ticketArea = this.resolveTicketArea(ticket);
        if (selectedArea === ticketArea) {
            this.addElementToArrayIfNotAddedYet(this.filteredByArea, ticket)
        }
    }
    private filterByTypes(ticket: Ticket) {
        const type = this.selectedType;
        let isTicketMatchingDiscount = false;
        switch (type) {
            case `normal`:
                isTicketMatchingDiscount = this.isRegularTicket(ticket);
                this.showDiscounts = false;
                break;
            case `reduced`:
                isTicketMatchingDiscount = !this.isRegularTicket(ticket);
                this.showDiscounts = true;
                break;
        }

        if (isTicketMatchingDiscount) {
            this.addElementToArrayIfNotAddedYet(this.filteredByType, ticket);
        }

    }

    private isRegularTicket(ticket: Ticket) {
        return (
            this.isTicketNameContainingPhrase(ticket, `regular`) ||
            this.isTicketNameContainingPhrase(ticket, `normal`) ||
            this.isTicketNameContainingPhrase(ticket, `normalny`)
        );
    }

    private isTicketNameContainingPhrase(ticket: Ticket, phrase: string) {
        return ticket.name!.toLowerCase().includes(phrase);
    }

    private addElementToArrayIfNotAddedYet(array: any[], element: any) {
        const index = array.findIndex((x) => x.id === element.id);

        if (index === -1) {
            array.push(element);
        }
    }

    resetPredefinedFilters() {
        this.selectedArea = null;
        this.selectedDiscount =``;
        this.selectedType = ``;
    }
}
