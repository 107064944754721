<section class="container mx-auto grid px-10 pt-10 items-center">
    <h1 class="text-4xl text-center md:text-left self-center py-10">
        {{ 'tickets.title' | translate }}
    </h1>

    <p-dataView
        #dv
        [value]="tickets"
        layout="grid"
        filterBy="name"
        styleClass="pb-3"
        [emptyMessage]="'tickets.noTickets' | translate"

    >
        <ng-template pTemplate="header" >
                <div class="pb-3">
                    <p-selectButton
                        [multiple]="false"
                        [options]="types"
                        [(ngModel)]="selectedType"
                        optionLabel="name"
                        optionValue="value"
                        (click)=filterBy()
                        styleClass="p-button-label"
                    >
                    </p-selectButton>
                </div>
                <div class="pb-3" *ngIf="showDiscounts">
                    <p-selectButton
                        [multiple]="false"
                        [options]="discounts"
                        [(ngModel)]="selectedDiscount"
                        optionLabel="name"
                        optionValue="id"
                        (click)=filterBy()
                        styleClass="p-button-label"
                    >
                    </p-selectButton>
                </div>
                <div class="pb-3">
                    <p-selectButton
                        [multiple]="false"
                        [options]="areas"
                        [(ngModel)]="selectedArea"
                        optionValue="area"
                        (click)=filterBy()
                        styleClass="p-button-label"
                    >
                        <ng-template let-item>
                            <div class="font-black">
                                <span>{{item.zone}}</span><br>
                                <span>{{item.area}}</span>
                            </div>
                        </ng-template>
                    </p-selectButton>
                </div>

            <div class="search-wrapper grid">
                <div class="flex justify-center self-center pl-2">
                    <div class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input
                            type="search"
                            pInputText
                            class="search-input"
                            [placeholder]="'tickets.search' | translate"
                            (input)="dv.filter($event.target.value, 'contains')"
                            (input)="resetPredefinedFilters()"
                            maxlength="200"
                        />
                    </div>
                </div>
                <div class="grid text-md description-wrapper self-center">
                    <div>{{ 'tickets.searchLabel' | translate }}</div>
                </div>
            </div>
        </ng-template>
        <ng-template let-ticket pTemplate="gridItem">
            <div class="ticket-wrapper flex">
                <div
                    class="grid-container bus-ticket bg-cover cursor-pointer m-2"
                    [routerLink]="ticket.id"
                    title="{{ resolveTicketZone(ticket)}} {{resolveTicketArea(ticket)}} {{resolveDiscount(ticket)}} ">
                    <div class="icon"></div>
                    <div class="content">
                        <div class="content-name">{{ resolveTicketZone(ticket) | translate }}</div>
                        <div class="content-item font-bold zone">{{resolveTicketArea(ticket) | translate}}</div>
                        <div class="content-body">
                            <div class="content-item discount">{{resolveDiscount(ticket) | translate}}</div>
                        </div>
                        <div class=" price">{{'ticket.price' | translate }}: {{ ticket.priceWithTax / 100 | currency: 'PLN' }}</div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dataView>
</section>
