/* eslint-disable class-methods-use-this */
import * as AppStateActions from '../../../../../modules/state/app-state.actions';
import * as _ from 'lodash';
import * as fns from 'date-fns';

import { ActivatedRoute, Router } from '@angular/router';
import {Component, PipeTransform, ViewChild} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
/* eslint-disable multiline-ternary */
import { Select, Store } from '@ngxs/store';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import {
    calculateViewTicketDiscount,
    calculateViewTicketDuration,
    calculateViewTicketZone,
} from '../../../../../../shared/utils/resolve-ticket-data';
import { Api } from '../../../../../../api/src/Api';
import { AppState } from '../../../../../modules/state/app.state';
import { CustomerInfo } from '../../../user/profile/views/customer-info/customer-info.interfaces';
import { Station, Ticket } from '../../../../../modules/state/interfaces';
import { getVisibleTicketCarriers } from '../../../../../../shared/utils/ticket-carriers';

interface ShoppingListItem {
    count: number;
    ticketId: string;
    ticketCarrierId: string;
    activatedAt: any;
    activeFromNow: boolean;
    ticket?: any;
    range?: { from: number; to: number };
    selectedLine?: any;
}

const createDifferentValueValidator = (controlName: string) =>
    (control: AbstractControl) => {
        if (!control.parent) {
            return null;
        }

        const siblingControl = control.parent!.get(controlName);

        const { value } = siblingControl || {};
        if (value && value === control.value) {
            return {
                stationsShouldBeDifferent: true,
            };
        }

        if (siblingControl?.invalid) {
            siblingControl?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }

        return null;
    };

const RESTRICTED_STATIONS_LINE_1 = [
    27, // Polesie
    28, // Milanówek Grudów
];

const RESTRICTED_STATIONS_LINE_2 = [
    21, // Kazimierówka
    22, // Brzózki
    23, // Grodzisk Mazowiecki Okrężna
    24, // Grodzisk Mazowiecki Piaskowa
    25, // Grodzisk Mazowiecki Jordanowice
    26, // Grodzisk Mazowiecki Radońska
];

const stationsAreExcludingEachOther = (firstStation: number, secondStation: number) =>
    firstStation &&
    secondStation &&
    RESTRICTED_STATIONS_LINE_1.includes(firstStation) && RESTRICTED_STATIONS_LINE_2.includes(secondStation) ||
    RESTRICTED_STATIONS_LINE_1.includes(secondStation) && RESTRICTED_STATIONS_LINE_2.includes(firstStation);

const createRestrictedStationsValidator = (controlName: string) =>
    (control: AbstractControl) => {
        if (!control.parent) {
            return null;
        }

        const siblingControl = control.parent!.get(controlName) || {} as AbstractControl;

        const { value: firstStation } = control;
        const { value: secondStation } = siblingControl;

        if (stationsAreExcludingEachOther(firstStation, secondStation)) {
            return {
                restrictedStations: true
            }
        }

        if (siblingControl?.invalid) {
            siblingControl?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }

        return null;
    };

@Component({
    selector: `app-transport-authority-tickets-type`,
    templateUrl: `transport-authority-tickets-type.component.html`,
    styleUrls: [`transport-authority-tickets-type.component.scss`],
})
export class TransportAuthorityTicketsTypeComponent{
    @ViewChild(`inputMaskCard`) pInputMask: any;

    @Select(AppState.selectedTransportAuthority)
    selectedTransportAuthority$!: Observable<any>;

    @Select(AppState.userData)
    userData$!: Observable<any>;

    @Select(AppState.tickets)
    tickets$!: Observable<any>;

    @Select(AppState.stations)
    stations$!: Observable<Station[]>;

    ticketCardInfoFormGroup = new FormGroup({
        cardName: new FormControl(``, [Validators.required]),
        cardNumber: new FormControl(``, [Validators.required]),
        cardOwner: new FormControl(``, [Validators.required]),
        cardExpiration: new FormControl(``, [Validators.required]),
    });

    // params!: {
    //     ticketId: string;
    //     transportAuthority: string;
    // };

    cardNumber = ``;
    quantity = 1;
    activatedAt: any;
    minActivationDate!: Date;
    maxActivationDate!: Date;

    selectedZone!: any;

    customerInfos!: CustomerInfo[];
    mappedInfos!: any[];

    token!: any;

    displayNewCardDialog = false;

    // cart
    moveToCartButtonLabel!: string;
    moveToCartButtonLabelGuest!: string;
    shoppingList: any;
    shoppingListItems: any;
    newShoppingListItems!: any[];

    selectedCustomerInfo!: any;
    selectedTickerCarrier!: any;
    selectedTransportAuthority!: any;
    selectedTicket!: any;
    selectedLine!: any;

    userData: any;

    // tickets
    tickets: any;

    // subscriptions
    transportAuthoritySubscription?: Subscription;
    userSubscription?: Subscription;
    valueChangesSubscription?: Subscription;

    //define the active property for select line
    private isActive : any = -1;

    readonly monthlyTicketForm: FormGroup = new FormGroup({
        from: new FormControl('',
            [
                Validators.required,
                createDifferentValueValidator('to'),
                createRestrictedStationsValidator('to')
            ]
        ),
        to: new FormControl('',
            [
                Validators.required,
                createDifferentValueValidator('from'),
                createRestrictedStationsValidator('from')
            ]),
    });

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly apiService: Api,
        private readonly messageService: MessageService,
        private readonly store: Store,
        private readonly router: Router,
        private readonly translateService: TranslateService,
    ) {
    }

    ngOnInit() {
        this.store.dispatch(new AppStateActions.ShowHeaderDropdown(false));
        this.store.dispatch(new AppStateActions.GetUser());
        this.store.dispatch(new AppStateActions.HideCart(true));
        this.token = localStorage.getItem(`token`);

        // this.currentDate = new Date();

        this.store.dispatch(new AppStateActions.Get()).subscribe(() => {
            this.store.dispatch(new AppStateActions.Deduce());
        });

        const tickets$ = this.selectedTransportAuthority$.pipe(
            filter(transportAuthority => !!transportAuthority),
            tap(transportAuthority => {
                this.selectedTransportAuthority = transportAuthority;
                this.tickets = this.selectedTransportAuthority.tickets;
            }),
            map(() => this.tickets),
        );

        this.transportAuthoritySubscription = combineLatest([
            tickets$,
            this.activatedRoute.params,
        ]).pipe(
            filter(([tickets]: any) => tickets?.length),
            map(([tickets, { ticketId }]) =>
                tickets.find(({ id, transportAuthorityId }: Ticket) =>
                    id === ticketId && transportAuthorityId === this.selectedTransportAuthority.id),
            ),
            filter(ticket => ticket)
        ).subscribe(ticket => {
            this.selectedTicket = ticket;

            this.updateQuantityIfSeasonTicket();
        })
        this.moveToCartButtonLabelGuest = `shop.buyAsGuest`;
        this.userSubscription = this.userData$.subscribe((userData) => {
            if (!userData) {
                this.moveToCartButtonLabel = `shop.loginFirst`;
                return;
            }
            this.moveToCartButtonLabel = `shop.addToCart`;
            this.userData = _.cloneDeep(userData);

            this.userData.ticketCarriers = [
                {
                    label: ``,
                    items: getVisibleTicketCarriers(this.userData.ticketCarriers),
                    hidden: true,
                },
                {
                    label: this.translateService.instant('tickets.newCard'),
                    items: [],
                    hidden: false,
                },
            ];
        });
        // if (this.userData.customerInfos) {
        //     this.customerInfos = this.userData.customerInfos;
        // }


        this.valueChangesSubscription = this.monthlyTicketForm.valueChanges
            .pipe(
                filter(() => this.monthlyTicketForm.valid),
                filter(formData => Object.values(formData).every(station => station != null && station != '')),
                switchMap(stations => {
                    const { name } = this.selectedTicket;
                    const params = {
                        ...stations,
                        name,
                    };
                    return this.apiService.distanceCalculator.getOptimalTicket(params);
                }),
                map(({ data }) => data),
            )
            .subscribe(({ dataTicket }) =>
                this.router.navigate([this.selectedTransportAuthority.slug, 'bilety', dataTicket.id])
            )
        if (fns.getDate(new Date()) > 20) {
            let nextMonthDate = fns.addMonths(new Date(), 1);
            this.activatedAt = fns.startOfMonth(nextMonthDate);
        } else {
            this.activatedAt = fns.startOfMonth(new Date());
        }

    }

    ngAfterViewInit() {
        this.pInputMask.initMask = function () {
            this.tests = [];
            this.partialPosition = this.mask.length;
            this.len = this.mask.length;
            this.firstNonMaskPos = null;
            this.defs = {
                '9': `[0-9]`,
                a: this.characterPattern,
                '^': `${this.characterPattern}|[0-9]`,
            };

            let maskTokens = this.mask.split(``);
            for (let i = 0; i < maskTokens.length; i++) {
                const c = maskTokens[i];
                if (c === `?`) {
                    this.len--;
                    this.partialPosition = i;
                } else if (this.defs[c]) {
                    this.tests.push(new RegExp(this.defs[c]));
                    if (this.firstNonMaskPos === null) {
                        this.firstNonMaskPos = this.tests.length - 1;
                    }
                    if (i < this.partialPosition) {
                        this.lastRequiredNonMaskPos = this.tests.length - 1;
                    }
                } else {
                    this.tests.push(null);
                }
            }

            this.buffer = [];
            for (let i = 0; i < maskTokens.length; i++) {
                let c = maskTokens[i];
                if (c !== `?`) {
                    if (this.defs[c]) {
                        this.buffer.push(this.getPlaceholder(i));
                    } else {
                        this.buffer.push(c);
                    }
                }
            }
            this.defaultBuffer = this.buffer.join(``);
        };
        this.pInputMask.initMask();
    }

    redirectToGuestInfo() {
        this.router.navigate(['uzytkownik/guest/data'], {
             queryParams: {
                redirect: this.router.url,
            }
        });
    }

    ngOnDestroy() {
        this.userSubscription?.unsubscribe();
        this.transportAuthoritySubscription?.unsubscribe();
        this.valueChangesSubscription?.unsubscribe();
    }

    addSelectedLine(idx: any, line: any) {
        this.selectedLine = line;
        this.isActive = idx;
    }


    get isSeasonTicket(): boolean {
        const { config } = this.selectedTicket || {};
        return !!config?.hasOwnProperty('range');
    }

    private updateQuantityIfSeasonTicket() {
        if (this.isSeasonTicket) {
            this.quantity = 1;
        }
    }

    // eslint-disable-next-line max-lines-per-function
    async moveToCart(userType: any) {
        if (!this.userData && userType === `regular`) {
            void this.router.navigate([`uzytkownik/logowanie`], {
                queryParams: {
                    redirect: this.router.url,
                },
            });
            return;
        }
        if (!this.userData && userType === `guest`) {
            void this.router.navigate([`uzytkownik/guest`], {
                queryParams: {
                    redirect: this.router.url,
                },
            });
            return;
        }
        if (this.userData.customerInfos[0] === undefined){
            this.redirectToGuestInfo();
        }

        this.selectedTickerCarrier = this.userData.ticketCarriers[0].items[0];

        if (!this.selectedTickerCarrier) {
            this.messageService.add({
                severity: `error`,
                summary: this.translateService.instant(`action.error`),
                detail: this.translateService.instant(`cart.chooseCard`),
            });
            return;
        }



        if (this.isSeasonTicket && this.monthlyTicketForm.invalid) {
            this.messageService.add({
                severity: `error`,
                summary: this.translateService.instant(`action.error`),
                detail: this.translateService.instant(`tickets.chooseStartEndStations`)
            });

            return;
        }

        this.shoppingList = this.userData.shoppingLists
            .find((shoppingList: any) => shoppingList.transportAuthorityId === this.selectedTransportAuthority?.id);

        const isAnyItemInCartSeasonTicket = this.shoppingList?.items?.some(({ range }: ShoppingListItem) => range != null);
        if (this.isSeasonTicket && isAnyItemInCartSeasonTicket) {
            this.messageService.add({
                severity: `error`,
                summary: this.translateService.instant(`action.error`),
                detail: this.translateService.instant(`cart.cannotAddMultiplePeriodTickets`)
            });

            return;
        }

        const ms = 1000 * 60;
        let pushActivationDate = new Date();
        let activeFromNow = false;

        // eslint-disable-next-line no-negated-condition
        if (!this.activatedAt) {
            pushActivationDate = new Date();
            activeFromNow = true;
        } else {
            pushActivationDate = new Date(Math.floor(this.activatedAt.getTime() / ms) * ms);
        }

        const { from, to } = this.monthlyTicketForm.value;

        const itemToPush: ShoppingListItem = {
            count: this.quantity,
            ticketCarrierId: this.selectedTickerCarrier.id,
            ticketId: this.selectedTicket!.id,
            range: this.isSeasonTicket ? { from, to } : undefined,
            activatedAt: pushActivationDate,
            activeFromNow,
            selectedLine: this.selectedLine,
        };

        if (!this.shoppingList) {
            this.newShoppingListItems = [];
            this.newShoppingListItems.push(itemToPush);

            await this.apiService.users
                .usersControllerUpdateShoppingList(
                    this.userData.id,
                    {
                        transportAuthorityId: this.selectedTransportAuthority.id,
                        items: this.newShoppingListItems,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    },
                ).then(()=>{
                        this.store.dispatch(new AppStateActions.GetUser());
                        return  this.messageService.add({
                            severity: `success`,
                            summary: this.translateService.instant(`action.success`),
                            detail: this.translateService.instant(`cart.addedToCart`),
                        });
                    }
                )
                .catch(() => {
                    return this.messageService.add({
                        severity: `error`,
                        summary: this.translateService.instant(`action.error`),
                        detail: this.translateService.instant(`cart.failedToAddToCart`),
                    });
                });




        }

        this.shoppingListItems = this.shoppingList.items;

        let index = -1;

        if (activeFromNow) {
            index = this.shoppingListItems.findIndex((item: any) => {
                return (
                    item.ticketId === this.selectedTicket.id &&
                    item.ticketCarrierId === this.selectedTickerCarrier.id &&
                    item.activeFromNow === true
                );
            });
        } else {
            index = this.shoppingListItems.findIndex((item: any) => {
                return (
                    item.ticketId === this.selectedTicket.id &&
                    item.ticketCarrierId === this.selectedTickerCarrier.id &&
                    fns.isEqual(new Date(item.activatedAt), pushActivationDate)
                );
            });
        }

        this.newShoppingListItems = _.cloneDeep(this.shoppingListItems);

        // eslint-disable-next-line no-negated-condition
        if (index !== -1) {
            this.newShoppingListItems[index].count += itemToPush.count;
        } else {
            this.newShoppingListItems.push(itemToPush);
        }

        await this.apiService.users
            .usersControllerUpdateShoppingList(
                this.userData.id,
                {
                    transportAuthorityId: this.selectedTransportAuthority.id,
                    items: this.newShoppingListItems,
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                },
            )
            .then(()=> {
                this.store.dispatch(new AppStateActions.GetUser());
                this.store.dispatch(new AppStateActions.HideCart(false));
                this.messageService.add({
                    severity: `success`,
                    summary: this.translateService.instant(`action.success`),
                    detail: this.translateService.instant(`cart.addedToCart`),
                });
            })
            .catch(() => {
                return this.messageService.add({
                    severity: `error`,
                    summary: this.translateService.instant(`action.error`),
                    detail: this.translateService.instant(`cart.cartAlreadyExist`),
                });
            });

    }

    onNewCardSelect() {
        this.displayNewCardDialog = true;
    }

    async createTicketCarrier() {
        this.token = localStorage.getItem(`token`);

        await this.apiService.users
            .usersControllerCreateTicketCarrier(this.userData.id, this.ticketCardInfoFormGroup.value, {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            })
            .catch(() => {
                this.messageService.add({
                    severity: `error`,
                    summary: this.translateService.instant(`action.error`),
                    detail: this.translateService.instant(`cart.cannotCreateCard`),
                });
                this.displayNewCardDialog = false;
                throw new Error(`Niepowodzenie`);
            });

        this.messageService.add({
            severity: `success`,
            summary: this.translateService.instant(`action.success`),
            detail: this.translateService.instant(`cart.cardCreated`),
        });

        await this.store.dispatch(new AppStateActions.GetUser()).toPromise();

        const newSelectedCarrier = this.userData.ticketCarriers
            .map((group: any) => {
                return group.items;
            })
            .flat()
            .find((carrier: any) => {
                return (
                    carrier.cardName === this.ticketCardInfoFormGroup.value.cardName &&
                    carrier.cardExpiration === this.ticketCardInfoFormGroup.value.cardExpiration
                );
            });



        this.displayNewCardDialog = false;
    }

    resetForm() {
        this.ticketCardInfoFormGroup.reset();
    }

    resolveTicketZone(ticket: any) {
        return calculateViewTicketZone(ticket);
    }

    resolveTicketDuration(ticket: any) {
        return calculateViewTicketDuration(ticket);
    }

    resolveTicketArea(ticket: any) {
        let area = '';
        if (ticket.tags && ticket.tags.length > 0 ) {
            ticket.tags.forEach(function (tag: any) {
                if (tag.tagGroupId === "5a3bc3cc-e6b2-4379-8074-208a00c7c8d4"){
                    area = tag.name;
                }
            })
        }
        return area;
    }
    resolveDiscount(ticket: any){
        return calculateViewTicketDiscount(ticket);
    }
}
