import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CommonModule } from '@angular/common';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LayoutModule } from '../../../../shared/components/layout';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TransportAuthorityTicketsComponent } from './views/transport-authority-tickets';
import { TransportAuthorityTicketsTypeComponent } from './views/transport-authority-tickets-type';
import { SelectButtonModule } from "primeng/selectbutton";
import { TranslateModule } from '@ngx-translate/core';

const primeng = [
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    DropdownModule,
    ButtonModule,
    DataViewModule,
    InputMaskModule,
    DialogModule,
    InputNumberModule,
    CalendarModule,
];

@NgModule({
    imports: [
        ...primeng,
        LayoutModule,
        CommonModule,
        ReactiveFormsModule,
        RouterModule.forChild([
            {
                path: ``,
                component: TransportAuthorityTicketsComponent,
            },
            {
                path: `:ticketId`,
                component: TransportAuthorityTicketsTypeComponent,
            },
        ]),
        SelectButtonModule,
        TranslateModule,
    ],
    declarations: [TransportAuthorityTicketsComponent, TransportAuthorityTicketsTypeComponent],
})
export class TicketsModule {}
